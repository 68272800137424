import React from "react";
import Layout from "../layout";
import styled from "styled-components";
import SEO from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import {
  navyBlue,
  mainRed,
  aku,
  innerWidth,
  transHover,
  gotham,
  screen,
} from "../components/common/variables";
import Arrow from "../images/svg/right-arrow.svg";
import logo from "../images/tenalphas-moving-content.jpg";

const Wrapper = styled.div`
  padding-top: 124px;
  padding-bottom: 90px;
  max-width: ${innerWidth};
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  @media ${screen.small} {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 135px;
  }
  @media ${screen.medium} {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
  }
  @media ${screen.xLarge} {
    padding-left: 0;
    padding-right: 0;
  }

  .heading {
    color: ${navyBlue};
    font-family: ${aku};
    margin: 0 auto 20px auto;
    font-size: 2.1rem;
    @media ${screen.small} {
      font-size: 2.7rem;
      margin: 0 auto 45px auto;
    }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle;
      @media ${screen.small} {
        width: 19px;
        margin-right: 14px;
      }
      @media ${screen.medium} {
        width: 24px;
      }

      .body {
        fill: ${mainRed};
        transition: ${transHover};
      }
    }
  }

  .banner {
    max-width: ${innerWidth};
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  .description {
    margin-top: 30px;
    max-width: 1000px;
    color: ${navyBlue};
    font-family: ${gotham};
    font-size: 1rem;
    @media ${screen.small} {
      font-size: 1.1rem;
    }
  }

  .awards-container {
    margin-top: 45px;
    &__award-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      @media ${screen.small} {
        margin-left: -20px;
        margin-right: -20px;
      }
      @media ${screen.large} {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
      }
      .award {
        margin-bottom: 45px;
        display: flex;
        flex-direction: column;
        width: 100%;
        @media ${screen.small} {
          margin-bottom: 60px;
          margin-left: 20px;
          margin-right: 20px;
          width: calc(50% - 40px);
        }
        @media ${screen.medium} {
          margin-bottom: 90px;
        }
        @media ${screen.large} {
          flex-direction: row;
          width: 100%;
          max-width: 1000px;
          margin-left: 0;
          margin-right: 0;
        }

        &__img {
          width: 100%;
          @media ${screen.medium} {
            max-width: 470px;
          }
          @media ${screen.xLarge} {
            max-width: 500px;
          }
        }

        &__texts-container {
          color: ${navyBlue};
          margin-top: 10px;
          @media ${screen.medium} {
            margin-left: 45px;
            margin-top: 0;
          }
          @media ${screen.large} {
            margin-left: 45px;
          }

          .title {
            font-family: ${aku};
            font-size: 1.9rem;
            font-weight: 400;
            margin-top: 10px;
            @media ${screen.small} {
              font-size: 2.1rem;
              margin-top: 15px;
            }
            @media ${screen.large} {
              margin-top: 0;
            }
          }

          .event-list {
            margin-top: 10px;
            @media ${screen.small} {
              margin-top: 20px;
            }
            @media ${screen.large} {
              margin-top: 30px;
            }

            .competition {
              font-size: 0.95rem;
              font-family: ${gotham};
              margin: 10px 0 10px 12px;
              display: flex;
              align-items: flex-start;
              line-height: 1.25;
              @media ${screen.small} {
                font-size: 1.1rem;
                margin: 10px 0 10px 20px;
              }
              @media ${screen.large} {
                margin: 10px 0 10px 35px;
              }

              svg {
                width: 7px;
                min-width: 7px;
                margin-right: 5px;
                margin-top: 4px;
                @media ${screen.small} {
                  margin-right: 10px;
                  margin-top: 6px;
                }
                @media ${screen.large} {
                  margin-right: 10px;
                  margin-top: 5px;
                }
                .body {
                  fill: ${navyBlue};
                }
              }
            }
          }
        }
      }
    }
  }
`;

const EquipmentPage = ({ data }) => {
  // basic hack to trigger the video modal component to watch location hash changes

  const imgBanner = data.content.data.banner.url;
  // Prismic content
  const pageTitle = data.content.data.page
    ? data.content.data.page.text
    : `Equipment`;
  const titleTag = data.content.data.title_tag
    ? data.content.data.title_tag
    : `Equipment`;
  const metaDescription = data.content.data.meta_description
    ? data.content.data.meta_description
    : `Equipment`;
  const description = data.content.data.description;

  // sort awards by year
  const sortedAwards = data.content.data.list.map((item) => {
    return {
      title: item.title,
      image: item.image.gatsbyImageData ? item.image.gatsbyImageData : null,
      sublist: item.sublist,
    };
  });

  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} image={logo} />
      <Wrapper>
        <h2 className="heading">
          <Arrow />
          {pageTitle}
        </h2>
        <div className="banner">
          <img src={imgBanner} alt="Header" />
        </div>
        <p className="description">{description}</p>

        <div className="awards-container">
          <ul className="awards-container__award-list">
            {sortedAwards.map((award, i) => (
              <li className="award" key={i}>
                {award.image && (
                  <div className="award__img">
                    <GatsbyImage image={award.image} alt="Equipment" />
                  </div>
                )}
                <div className="award__texts-container">
                  <h3 className="title">{award.title}</h3>

                  {award.sublist.raw.length && (
                    <ul className="event-list">
                      {award.sublist.raw.map((competition, i) => (
                        <li className="competition" key={i}>
                          <Arrow /> <span>{competition.text}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default EquipmentPage;

export const dataQuery = graphql`
  {
    content: prismicEquipmentPage {
      data {
        banner {
          alt
          url
        }
        description
        title_tag
        meta_description
        page {
          text
        }
        list {
          title
          image {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
          sublist {
            raw
          }
        }
      }
    }
  }
`;
